var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Evaluations")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-select',{staticClass:"mt-1",attrs:{"label":"limit","items":[10, 20, 50, 100, 200],"filled":"","dense":""},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.evaluations,"hide-default-footer":true,"disable-pagination":"","single-select":"","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.id).toLocaleString()))])]}},{key:"item.evaluationStartTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.evaluationStartTime).toLocaleString()))])]}},{key:"item.evaluationCompleteTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.evaluationCompleteTime).toLocaleString()))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getDuration(item) > 100 ? 'red--text' : ''},[_vm._v(_vm._s(((_vm.getDuration(item)) + " ms")))])]}},{key:"item.errorCount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.errorCount >= 1 ? 'red--text' : ''},[_vm._v(_vm._s(item.errorCount))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }